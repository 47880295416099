(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var $ = require('jquery');
require('gsap');
require('gsap/ScrollToPlugin');
global.jQuery = $;
global.$ = $;
var Swiper = require('swiper');
var Mason = require('masonry-layout');

$(document).ready(function() {

	var TABLET_WIDTH = 768;

	var previousWidth = window.innerWidth;

	/*
		BURGER MENU
	*/
	var burgerMenuIsActive = true;
	var burgerMenuOpen = false;
	var tlBurgerMenu;

	$('.burgerMenu').on('click', function(e) {
		if (burgerMenuIsActive) {
			if (!burgerMenuOpen) {
				openBurgerMenu();
			} else {
				closeBurgerMenu();
			}
		}
	});

	function openBurgerMenu() {
		burgerMenuIsActive = false;
		burgerMenuOpen = true;

		var burgerMenu = document.querySelector('.burgerMenu');
		var lineTop = burgerMenu.querySelector('.top');
		var linesMiddle = burgerMenu.querySelectorAll('.middle');
		var lineBottom = burgerMenu.querySelector('.bottom');
		var myNavBar = document.querySelector('.myNavBar');

		tlBurgerMenu = new TimelineLite({
			onComplete: function() {
				burgerMenuIsActive = true;
			},
			onReverseComplete: function() {
				burgerMenuIsActive = true;
				burgerMenuOpen = false;
			}
		});
    tlBurgerMenu.to(lineTop, 0.3, { top: $(linesMiddle[0]).position().top, ease: Expo.easeIn, onComplete: function() { lineTop.style.display = 'none'; } }, 0);
    tlBurgerMenu.to(lineBottom, 0.3, { top: $(linesMiddle[0]).position().top, ease: Expo.easeIn, onComplete: function() { lineBottom.style.display = 'none'; } }, 0);
    tlBurgerMenu.to(linesMiddle[0], 1, { rotation: -405, ease: Expo.easeInOut }, 0.2);
    tlBurgerMenu.to(linesMiddle[1], 1, { rotation: -315, ease: Expo.easeInOut }, 0.2);
    tlBurgerMenu.fromTo(myNavBar, 1, { y: '-100%' }, { display: 'block', y: '0%', ease: Expo.easeInOut }, 0);
	}

	function closeBurgerMenu() {
		var burgerMenu = document.querySelector('.burgerMenu');
		var lineTop = burgerMenu.querySelector('.top');
		var linesMiddle = burgerMenu.querySelectorAll('.middle');
		var lineBottom = burgerMenu.querySelector('.bottom');

		TweenLite.set(lineTop, { display: 'block', delay: 0.8 });
    TweenLite.set(lineBottom, { display: 'block', delay: 0.8 });

    tlBurgerMenu.reverse();
	}

	function reinitBurgerMenu() {
		burgerMenuOpen = false;
		burgerMenuIsActive = true;

		var burgerMenu = document.querySelector('.burgerMenu');
		var lineTop = burgerMenu.querySelector('.top');
		var linesMiddle = burgerMenu.querySelectorAll('.middle');
		var lineBottom = burgerMenu.querySelector('.bottom');
		var myNavBar = document.querySelector('.myNavBar');

		TweenLite.set(lineTop, { clearProps: 'all' });
		TweenLite.set(linesMiddle[0], { clearProps: 'all' });
		TweenLite.set(linesMiddle[1], { clearProps: 'all' });
		TweenLite.set(lineBottom, { clearProps: 'all' });
		TweenLite.set(myNavBar, { clearProps: 'all' });
	}


	/*
		NAV BAR
	*/
	$('.navItem').on('click', function(e) {
		var hash = e.currentTarget.hash;
		if (hash === "") return;

		e.preventDefault();
		if(history.pushState) {
		  history.pushState(null, null, hash);
		  var element = document.querySelector(hash);
		  if (element) {
		  	var scrollTop = element.offsetTop;
		  	TweenLite.to(window, 1.0, { scrollTo: { y: scrollTop, autoKill: false }, ease: Cubic.easeInOut });
		  }
		}
		else {
		  location.hash = hash;
		}

		// Close BurgerMenu if open
		if (burgerMenuOpen && burgerMenuIsActive) {
			closeBurgerMenu();
		}
	});

	/*
		ON CALL VIEW
	*/
	$(window).load(function() {
		$('.labelNight').click();
		initMasonry();
	})

	/*
		COMMON
	*/

	// PARALLAX
	$(document).on('scroll', function() {
		const bound = document.querySelector('.stone').getBoundingClientRect();
		const image = document.querySelector('.imgStoneNight');

		var startY = Math.round(window.innerHeight * 0.5);
		var endY = Math.round(window.innerHeight * 0.15);

		if (bound.top <= startY && bound.top >= endY) {
			var diffY = bound.top - endY;
			var percent = 1 - (diffY / (startY - endY));

			TweenLite.set(image, { opacity: percent });
		}
	});

	/*
		BLOCK IMG HEADER
		TRANSITION DAY NIGHT
	*/
	var idTimeOut;
	var tlHeroHeader;

	$('.labelDay').on('click', function(e) {
		var imgHeaderNight = $('.imgHeaderNight');
		var bar = $('.myBar');
		var barGreen = $('.myBar .bar-green');
		var cursorMoon = $('.myBar .cursor .cursor-moon');
		var barPourcentage = bar.width() / bar.parent().width() * 100;
		var t = (barPourcentage / 100) * 5;

		if (idTimeOut) {
			clearTimeout(idTimeOut);
			idTimeOut = undefined;
		}

		if (tlHeroHeader) {
			tlHeroHeader.kill();
		}
		tlHeroHeader = new TimelineLite({
			onComplete: function() {
				idTimeOut = setTimeout(function() {
					$('.labelNight').click();
				}, 4000);
			}
		});
		tlHeroHeader.to(imgHeaderNight, t, { autoAlpha: 0, ease: Linear.easeNone }, 0);
		tlHeroHeader.to(bar, t, { width: '0%', ease: Linear.easeNone }, 0);
		tlHeroHeader.to(barGreen, t, { autoAlpha: 0, ease: Linear.easeNone }, 0);
		tlHeroHeader.to(cursorMoon, t, { autoAlpha: 0, ease: Linear.easeNone }, 0);
	});

	$('.labelNight').on('click', function(e) {
		var imgHeaderNight = $('.imgHeaderNight');
		var bar = $('.myBar');
		var barGreen = $('.myBar .bar-green');
		var cursorMoon = $('.myBar .cursor .cursor-moon');
		var barPourcentage = bar.width() / bar.parent().width() * 100;
		var t = 5 - (barPourcentage / 100) * 5;

		if (idTimeOut) {
			clearTimeout(idTimeOut);
			idTimeOut = undefined;
		}

		if (tlHeroHeader) {
			tlHeroHeader.kill();
		}
		tlHeroHeader = new TimelineLite({
			onComplete: function() {
				idTimeOut = setTimeout(function() {
					$('.labelDay').click();
				}, 4000);
			}
		});
		tlHeroHeader.to(imgHeaderNight, t, { autoAlpha: 1, ease: Linear.easeNone }, 0);
		tlHeroHeader.to(bar, t, { width: '100%', ease: Linear.easeNone }, 0);
		tlHeroHeader.to(barGreen, t, { autoAlpha: 1, ease: Linear.easeNone }, 0);
		tlHeroHeader.to(cursorMoon, t, { autoAlpha: 1, ease: Linear.easeNone }, 0);
	});

	/*
		BLOCK FOR WHAT USES
	*/
	var swiper = new Swiper('.mySwiper .mySliderDesc .swiper-container', {
		direction: 'horizontal',
	    slidesPerView: 1,
	    nextButton: '.mySwiper .arrow-right',
	    prevButton: '.mySwiper .arrow-left',
	    pagination: '#usages .myPagination',
        paginationClickable: true,
        loop: true,
        autoplay: 7000,
        autoplayDisableOnInteraction: false,
        onSlideChangeStart: function(swiper) {
        	var slideId = $('.swiper-slide-active').attr('data-index');
        	$('.myBlock').removeClass('active');
        	$(".myBlock[data-index='" + slideId + "']").addClass('active');
        }
	});

	/*
		BLOCK FAQ
	*/
	$('.blockFaq').on('click', function(e) {
		var state = ($(e.currentTarget).children(".responseFaq").css('display') === 'none') ? 'close' : 'open';
		// Toggle text
		$(e.currentTarget).children(".responseFaq").toggle(300);
		// Rotate arrow
		var deg = (state === 'close') ? 90 : 0;
		var arrow = $(e.currentTarget).find('.arrow .icon-arrow');
		TweenLite.to(arrow, 0.27, { rotation: deg, ease: Cubic.easeInOut });
	});

	/*
		BLOCK CONTACT
	*/
	var contactDesc = document.querySelector('.blockContact .contactUs .contactDesc');
	var contactDescText = contactDesc.innerHTML;
	// Replace \n to <br />
	contactDescText = contactDescText.replace(/\n/g, "<br />");
	// Remove first <br />
	contactDescText = contactDescText.slice(contactDescText.indexOf('<br />')+6, contactDescText.length);
	// Replace old text by new text with <br />
	contactDesc.innerHTML = contactDescText;

	/*
		GRID MASONRY
	*/
	function initMasonry() {
		var url = window.location.pathname;

		if(url == "/galerie/") {
			var msnry = new Mason('.grid', {
				columnWidth: '.grid-sizer',
  				itemSelector: '.grid-item',
  				gutter: 30
			});
		}
	}

	/*
		RESIZE
	*/
	window.addEventListener('resize', function(e) {
		if (previousWidth < TABLET_WIDTH && window.innerWidth >= TABLET_WIDTH) {
			reinitBurgerMenu();
		}
		previousWidth = window.innerWidth;
	}, false);

});

}).call(this,require("wweLnl"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_d21c2ee9.js","/")